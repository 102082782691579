<template>
  <b-container class="col">
    <b-row>
      <b-col md="12" lg="3">
        <b-form-group label="Select Date">
          <b-card no-body>
            <flat-pickr
              v-model="date"
              :config="{
                // dateFormat: 'F j, Y',
                dateFormat: 'Y-m-d',
                defaultDate: 'today',
                // mode: 'range',
              }"
              class="form-control font-small-3"
              placeholder="Choose Date"
              readonly="readonly"
              @input="getallTransaction(date)"
            />
          </b-card>
        </b-form-group>
      </b-col>
    </b-row>

    <b-card>
      <MyTable
        :columns="columns"
        :rows="rows"
        :is-loading="isLoading"
        :hasExport="true"
        @handleExport="handleExport"
      >
        <template v-slot="{ props }">
          <span v-if="props.column.field == 'id'">
            <b-button
              v-if="props.row.shipment == 'Outlet'"
              variant="gradient-primary"
              block
              >F{{ props.row.id }}</b-button
            >
            <b-button
              v-else-if="props.row.shipment == 'Singapore'"
              variant="gradient-primary"
              block
              >S{{ props.row.id }}</b-button
            >
            <b-button v-else variant="gradient-primary" block>{{
              props.row.id
            }}</b-button>
          </span>
          <span v-else-if="props.column.field === 'route_id'">
            {{ props.row.route_id === 0 ? "Not Routed" : props.row.route_id }}
          </span>
          <span v-else-if="props.column.field === 'tax'">
            {{ props.row.tax }}%
          </span>

          <span v-else-if="props.column.field === 'grand_total'">
            {{ $helpers.formatTotal(props.row.grand_total) }}
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </MyTable>
    </b-card>
  </b-container>
</template>

<script>
import exportFromJSON from "export-from-json";
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";

export default {
  components: {
    flatPickr,
    MyTable,
  },

  data() {
    return {
      rows: [],
      pageLength: 5,
      searchTerm: "",
      exportType: "",
      isLoading: false,
      date: new Date().toISOString(),
      columns: [
        {
          label: "Order ID",
          field: "id",
        },
        {
          label: "Delivery ID",
          field: "route_id",
        },
        {
          label: "Delivery Date",
          field: "delivery_date",
        },
        {
          label: "Shipment",
          field: "shipment",
        },
        {
          label: "Sender's Name",
          field: "sender_name",
        },
        {
          label: "Recipient's Name",
          field: "recipient_name",
        },
        {
          label: "Origin Postcode",
          field: "sender_postcode",
        },
        {
          label: "Destination Postcode",
          field: "destination_postcode",
        },
        {
          label: "QTY",
          field: "qty",
        },
        {
          label: "Tax",
          field: "tax",
        },
        {
          label: "Grand Total",
          field: "grand_total",
        },

        {
          label: "Payment Method",
          field: "payment_method",
        },
      ],
    };
  },

  created() {
    this.getallTransaction();
  },

  methods: {
    handleExport(type) {
      const data = this.rows;
      const fileName = "generated-transaction";
      let exportType;
      switch (type) {
        case "xlsx":
          exportType = exportFromJSON.types.xls;
          exportFromJSON({ data, fileName, exportType });
          break;
        case "csv":
          exportType = exportFromJSON.types.csv;
          exportFromJSON({ data, fileName, exportType });
          break;
      }
      this.exportType = "";
    },

    async getallTransaction(date = new Date().toISOString()) {
      this.isLoading = true;
      const formattedDate = date.split("T")[0];
      const response = await this.$http.get(
        `transactions_list/${formattedDate}`
      );
      this.rows = response.data.data;
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
