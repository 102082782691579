var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"col"},[_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Select Date"}},[_c('b-card',{attrs:{"no-body":""}},[_c('flat-pickr',{staticClass:"form-control font-small-3",attrs:{"config":{
              // dateFormat: 'F j, Y',
              dateFormat: 'Y-m-d',
              defaultDate: 'today',
              // mode: 'range',
            },"placeholder":"Choose Date","readonly":"readonly"},on:{"input":function($event){return _vm.getallTransaction(_vm.date)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1)],1),_c('b-card',[_c('MyTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"is-loading":_vm.isLoading,"hasExport":true},on:{"handleExport":_vm.handleExport},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var props = ref.props;
return [(props.column.field == 'id')?_c('span',[(props.row.shipment == 'Outlet')?_c('b-button',{attrs:{"variant":"gradient-primary","block":""}},[_vm._v("F"+_vm._s(props.row.id))]):(props.row.shipment == 'Singapore')?_c('b-button',{attrs:{"variant":"gradient-primary","block":""}},[_vm._v("S"+_vm._s(props.row.id))]):_c('b-button',{attrs:{"variant":"gradient-primary","block":""}},[_vm._v(_vm._s(props.row.id))])],1):(props.column.field === 'route_id')?_c('span',[_vm._v(" "+_vm._s(props.row.route_id === 0 ? "Not Routed" : props.row.route_id)+" ")]):(props.column.field === 'tax')?_c('span',[_vm._v(" "+_vm._s(props.row.tax)+"% ")]):(props.column.field === 'grand_total')?_c('span',[_vm._v(" "+_vm._s(_vm.$helpers.formatTotal(props.row.grand_total))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }